<template>
  <b-card>
    <div v-for="(question, indexQ) in questions" :key="indexQ">
      <b-card-text class="mt-1 text-black">
        <h4 class="text-black" v-html="question ? question.text : ''"></h4>
      </b-card-text>
      <b-form-group label="" v-slot="{ ariaDescribedby }"
                    v-if="question.options && question.options.length > 0"
      >
        <b-form-radio class="mt-1 text-black " :aria-describedby="ariaDescribedby"
                      :name="'some-radios' + '-' + question.id" v-model="question.id + '-' + value.selected" :value="question.id + '-' + 1" disabled
                      v-for="(value, index) in question.options" :key="index"
        >
          <span v-html="value.text"></span><span :class="value.is_correct === value.selected ? 'badge badge-success' : 'badge badge-danger'" v-if="value.selected === 1">{{value.is_correct === value.selected ? 'Правильно' : 'Неправильно'}}</span>
        </b-form-radio>
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormRadio, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BCardText,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      questions: [],
    }
  },
  methods: {
    view() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/report/test/${this.id}/view`, {})
        .then(response => {
          const {
            data,
          } = response.data
          this.questions = data
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.data.message,
            },
          })
        })
    },
  },
  created() {
    this.view()
  },
  setup() {
    const id = router.currentRoute.params.id
    return {
      id,
    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label{
  color: #222222 !important;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
